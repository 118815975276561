import React, { lazy, Suspense } from "react";
import Skeleton from "react-loading-skeleton";

import TicketTypeTooltip from "./TicketTypeTooltip";
import EmptySeatTooltip from "./EmptySeatTooltip";
import TicketTypeDrawer from "./TicketTypeDrawer";
import { showAlert } from "../showAlert";
import { FaMagnifyingGlassMinus, FaMagnifyingGlassPlus } from "react-icons/fa6";

import TwinSeat from "../../assets/images/seat-icons/double-seat.svg";
import TwinSeatBooked from "../../assets/images/seat-icons/double-seat-booked.svg";
import TwinSeatSelected from "../../assets/images/seat-icons/double-seat-selected.svg";
import AlertIcon from "../../assets/images/alert-icon.svg";
import ScreenView from "../../assets/images/screen-view.svg";
import infoIcon from "../../assets/images/info-icon.svg";

import { ReactComponent as SeatOutlinedIcon } from "../../assets/images/seat-icons/seat-outlined-icon.svg";
import { ReactComponent as SeatFilledIcon } from "../../assets/images/seat-icons/seat-filled-icon.svg";
import { ReactComponent as PremiumEmptySeat } from "../../assets/images/seat-icons/premium-empty-seat.svg";
import { ReactComponent as WheelchairOutlinedIcon } from "../../assets/images/seat-icons/wheelchair-outlined-icon.svg";
import { ReactComponent as WheelchairFilledIcon } from "../../assets/images/seat-icons/wheelchair-filled-icon.svg";
import { ReactComponent as WheelChairSelectedIcon } from "../../assets/images/seat-icons/wheelchair-selected-icon.svg";
import { ReactComponent as CompanionOutlinedIcon } from "../../assets/images/seat-icons/companion-outlined-icon.svg";
import { ReactComponent as CompanionSelectedIcon } from "../../assets/images/seat-icons/companion-selected-icon.svg";
import { ReactComponent as SeatSelectedIcon } from "../../assets/images/seat-icons/seat-selected-icon.svg";
import { ReactComponent as PremiumSelectedSeat } from "../../assets/images/seat-icons/premium-selected-seat.svg";
import { ReactComponent as PremiumBookedSeat } from "../../assets/images/seat-icons/premium-booked-seat.svg";

import "./index.scss";
import * as API from "../../configuration/apiconfig";
import CustomModal from "../../components/CustomModal";

class Seats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eachSeatWidth: 0,
      eachSeatHeight: 0,
      totalSeatsWidth: null,
      ticketTypeComponent: process.env.REACT_APP_TICKET_TYPE_COMPONENT,
      icons_based_on_area: JSON.parse(process.env.REACT_APP_ICONS_BASED_ON_AREA),
      zoomSeats: false,
      seatsData: [],
      allowSingleSeatSelection: [],
      selectedSofaSeatGroupName: null,
      showChangeTicketTypeTooltip: false,
      applyToAllSeats: false,
      ticketTypes: [],
      seatTypeAlertContent: [],
      showSeatTypeAlert: false,
      maxSeatCount: 100,
      alertData: {},
    };
  }

  componentDidMount() {
    const elmnt = document.getElementById("seats-container");
    if (!elmnt) return;
    this.setState({ totalSeatsWidth: elmnt.offsetWidth });
  }

  componentDidUpdate() {
    if (this.state.seatsData.length == 0 && this.props.seatsData.length > 0) {
      const { allowSingleSeatSelection, seatsData } = this.props;
      this.setState({ seatsData, allowSingleSeatSelection }, () => {
        var ticketTypes = [];
        seatsData.forEach((seatType) => {
          ticketTypes.push(...seatType.tickettypes);
        });
        ticketTypes = Array.from(new Set(ticketTypes.map(JSON.stringify))).map(JSON.parse);
        this.setState({ ticketTypes }, () => {
          {
            this.getSiteSettingsData();
          }
        });
      });
    } else if (this.props.seatsData.length == 0 && this.state.seatsData.length != 0) {
      this.setState({ seatsData: [] });
    }
  }

  calculateEachSeatWidth = (seatClass) => {
    const { seatsData } = this.state;
    const { totalSeatsWidth, eachSeatWidth } = this.state;

    var highestNumberOfSeatsInARow = seatsData[0].cols;
    var eachSeatWidthTemp = totalSeatsWidth ? totalSeatsWidth / (highestNumberOfSeatsInARow + 2) : 30;
    var eachSeatHeightTemp = eachSeatWidthTemp * (16 / 15);

    if (seatClass == "seat-sofa") {
      eachSeatWidthTemp = eachSeatWidth * 2;
      eachSeatHeightTemp = eachSeatWidthTemp / 2;
    }
    if (eachSeatWidth != eachSeatWidthTemp && seatClass != "seat-sofa") {
      this.setState({ eachSeatHeight: eachSeatHeightTemp, eachSeatWidth: eachSeatWidthTemp });
    }
    var zoomSeats = zoomSeats ? 2 : 1;
    return {
      eachSeatHeightCalculated: eachSeatHeightTemp,
      eachSeatWidthCalculated: eachSeatWidthTemp * zoomSeats,
    };
  };

  checkIfSeatIsSelected = (seatName) => {
    const { selectedSeats } = this.props;
    let selectedSeatsTemp = selectedSeats;
    let selectedTicketType;
    let checkIfSeatIsSelected = selectedSeatsTemp.some((el) => {
      selectedTicketType = el.ticketType.description[0];
      return el.name === seatName;
    });
    return checkIfSeatIsSelected ? "selected-seat" : "";
  };

  handleSofaSeatImage = (seat, sofaSeatSelected, groupName, ticketStatus, groupIndex) => {
    const { selectedSeats } = this.props;
    const { eachSeatWidth, eachSeatHeight, icons_based_on_area } = this.state;
    let selectedSeatsTemp = selectedSeats;
    let selectedTicketType = "";

    // Check if the seat is selected
    // selectedSeatsTemp.some((el) => {
    //   // console.log(el, "testing el");
    //   if (el.name === groupName) {
    //     if (el?.ticketType?.description[0]) {
    //       selectedTicketType = el?.ticketType?.description[0];
    //     }
    //     return true;
    //   }
    //   return false;
    // });

    selectedSeatsTemp.forEach((el) => {
      if (el.name === groupName) {
        if (el?.ticketType?.description[0]) {
          selectedTicketType = el?.ticketType?.description[0];
        }
      }
    });

    let iconType = null;
    // Determine the icon type based on the area
    icons_based_on_area.forEach((iconTypeTemp) => {
      if (seat.area === iconTypeTemp.area) {
        iconType = iconTypeTemp.icon;
      }
    });
    // console.log(iconType, "iconType");
    if (iconType === null) {
      iconType = "normal";
    }

    let seatImageUrl = "";
    seatImageUrl += iconType + "/";
    let singleSeatAllowed = this.props.allowSingleSeatSelection.find((item) => item.area === seat.area)?.isAllowed;
    let sofaSeatSide = groupIndex === 0 ? "left" : "right";
    // console.log(seatImageUrl, "seatImageUrl iconTYpe");
    if (ticketStatus.toLowerCase() === "sold" || ticketStatus.toLowerCase() === "broken") {
      seatImageUrl += (singleSeatAllowed ? `sofa-${sofaSeatSide}-booked.svg` : `sofa-booked.svg`);
    } else if (sofaSeatSelected) {
      if (/^[a-zA-Z()]+$/.test(selectedTicketType)) {
        seatImageUrl += (singleSeatAllowed ? `sofa-${sofaSeatSide}-selected-${selectedTicketType.toLowerCase()}.svg` : `sofa-selected-${selectedTicketType.toLowerCase()}.svg`);
      } else {
        seatImageUrl += (singleSeatAllowed ? `sofa-${sofaSeatSide}-selected.svg` : `sofa-selected.svg`);
      }
    } else {
      seatImageUrl += (singleSeatAllowed ? `sofa-${sofaSeatSide}-empty.svg` : `sofa-empty.svg`);
    }

    // console.log(seatImageUrl, "seatImageUrl");

    // Use require.context to import images from the specified folder
    try {
      // Create a context for the images folder
      const context = require.context('../../assets/images/seat-icons', true, /\.(png|jpe?g|svg)$/);

      // Use the dynamic relative path to import the image
      return context(`./${seatImageUrl}`);
    } catch (error) {
      console.error("Image not found:", error);
      return null; // Return null or a fallback image if not found
    }
  };

  renderSeatImages = (seat) => {
    const { selectedSeats } = this.props;
    const { eachSeatWidth, eachSeatHeight } = this.state;
    let selectedSeatsTemp = selectedSeats;
    let selectedTicketType;
    let iconType = null;
    let checkIfSeatIsSelected = selectedSeatsTemp.some((el) => {
      if (el.name === seat.name) {
        selectedTicketType = el.ticketType.description[0];
        return true;
      }
      return false;
    });

    this.state.icons_based_on_area.forEach((iconTypeTemp) => {
      if (seat.area === iconTypeTemp.area) {
        iconType = iconTypeTemp.icon;
      }
    });
    if (iconType === null) {
      iconType = "normal";
    }
    let seatImageUrl = "";
    seatImageUrl += iconType + "/";

    if (seat.style.toLowerCase() === "wheelchair") {
      if (seat.status.toLowerCase() === "empty") {
        if (checkIfSeatIsSelected) {
          seatImageUrl += "wheelchair-selected.svg";
          // return <WheelChairSelectedIcon width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
          // return
        } else {
          seatImageUrl += "wheelchair-empty.svg";
          // return <WheelchairOutlinedIcon width={eachSeatWidth} height={eachSeatHeight} />;
        }
      } else if (seat.status.toLowerCase() === "sold" || seat.status.toLowerCase() === "broken") {
        // return <WheelchairFilledIcon width={eachSeatWidth} height={eachSeatHeight} className="booked-seat" />;
        seatImageUrl += "wheelchair-booked.svg";
      }
    } else if (seat.style.toLowerCase() === "companion") {
      if (seat.status.toLowerCase() === "empty") {
        if (checkIfSeatIsSelected) {
          // return <CompanionSelectedIcon width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
          seatImageUrl += "companion-selected.svg";
        } else {
          // return <CompanionOutlinedIcon width={eachSeatWidth} height={eachSeatHeight} />;
          seatImageUrl += "companion-empty.svg";
        }
      } else if (seat.status.toLowerCase() === "sold" || seat.status.toLowerCase() === "broken") {
        // return <SeatFilledIcon width={eachSeatWidth} height={eachSeatHeight} className="booked-seat" />;
        seatImageUrl += "companion-booked.svg";
      }
    } else
      if (seat.style.toLowerCase() === "normal") {
        // if (iconType === "premium") {
        //   if (seat.status.toLowerCase() === "empty") {
        //     if (checkIfSeatIsSelected) {
        //       // return this.getSelectedSeatIcon(iconType, selectedTicketType, eachSeatWidth, eachSeatHeight);
        //       seatImageUrl += "premium-selected-seat.svg";
        //     } else {
        //       // return <PremiumEmptySeat width={eachSeatWidth} height={eachSeatHeight} />;
        //       seatImageUrl += "premium-empty-seat.svg";
        //     }
        //   } else if (seat.status.toLowerCase() === "sold" || seat.status.toLowerCase() === "broken") {
        //     // return <PremiumBookedSeat width={eachSeatWidth} height={eachSeatHeight} className="booked-seat" />;
        //     seatImageUrl += "premium-booked-seat.svg";
        //   }
        // } else {
        //   if (seat.status.toLowerCase() === "empty") {
        //     if (checkIfSeatIsSelected) {
        //       // return this.getSelectedSeatIcon(iconType, selectedTicketType, eachSeatWidth, eachSeatHeight);
        //       seatImageUrl += "seat-selected-icon.svg";
        //     } else {
        //       // return <SeatOutlinedIcon width={eachSeatWidth} height={eachSeatHeight} />;
        //       seatImageUrl += "seat-outlined-icon.svg";
        //       return seatImageUrl;
        //     }
        //   } else if (seat.status.toLowerCase() === "sold" || seat.status.toLowerCase() === "broken") {
        //     // return <SeatFilledIcon width={eachSeatWidth} height={eachSeatHeight} className="booked-seat" />;
        //     seatImageUrl += "seat-filled-icon.svg";
        //   }
        // }

        if (seat.status.toLowerCase() === "empty") {
          if (checkIfSeatIsSelected) {
            // return this.getSelectedSeatIcon(iconType, selectedTicketType, eachSeatWidth, eachSeatHeight);
            if (/^[a-zA-Z()]+$/.test(selectedTicketType)) {
              seatImageUrl += `seat-selected-${selectedTicketType.toLowerCase()}.svg`;
            } else {
              seatImageUrl += "seat-selected.svg";
            }
          } else {
            // return <SeatOutlinedIcon width={eachSeatWidth} height={eachSeatHeight} />;
            seatImageUrl += "seat-empty.svg";
          }
        } else if (seat.status.toLowerCase() === "sold" || seat.status.toLowerCase() === "broken") {
          // return <SeatFilledIcon width={eachSeatWidth} height={eachSeatHeight} className="booked-seat" />;
          seatImageUrl += "seat-booked.svg";
        }
      }

    // Use require.context to import images from the specified folder
    try {
      // Create a context for the images folder
      const context = require.context('../../assets/images/seat-icons', true, /\.(png|jpe?g|svg)$/);

      // Use the dynamic relative path to import the image
      return context(`./${seatImageUrl}`);
    } catch (error) {
      console.error("Image not found:", error);
      return null; // Return null or a fallback image if not found
    }
  };

  handleZoomSeats = () => {
    this.setState({ zoomSeats: !this.state.zoomSeats }, () => {
      if (this.state.zoomSeats) {
        setTimeout(() => {
          document.getElementById("screenImageBlankRowTitleLeft").scrollIntoView();
        }, 500);
      }
    });
  };

  getSelectedSeatIcon = (iconType, selectedTicketType, eachSeatWidth, eachSeatHeight) => {
    console.log(selectedTicketType, "selectedTicketType");
    // if (iconType === "premium") {
    //   switch (selectedTicketType) {
    //     case "A":
    //       return <ASelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "B":
    //       return <BSelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "C":
    //       return <CSelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "D":
    //       return <DSelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "E":
    //       return <ESelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "F":
    //       return <FSelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "G":
    //       return <GSelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "H":
    //       return <HSelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "I":
    //       return <ISelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "J":
    //       return <JSelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "K":
    //       return <KSelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "L":
    //       return <LSelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "M":
    //       return <MSelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "N":
    //       return <NSelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "O":
    //       return <OSelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "P":
    //       return <PSelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "Q":
    //       return <QSelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "R":
    //       return <RSelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "S":
    //       return <SSelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "T":
    //       return <TSelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "U":
    //       return <USelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "V":
    //       return <VSelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "W":
    //       return <WSelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "X":
    //       return <XSelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "Y":
    //       return <YSelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "Z":
    //       return <ZSelectedPremium width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     default:
    //       return <PremiumSelectedSeat width={eachSeatWidth} height={eachSeatHeight} />;
    //   }
    // } else {
    //   switch (selectedTicketType) {
    //     case "A":
    //       return <ASelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "B":
    //       return <BSelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "C":
    //       return <CSelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "D":
    //       return <DSelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "E":
    //       return <ESelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "F":
    //       return <FSelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "G":
    //       return <GSelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "H":
    //       return <HSelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "I":
    //       return <ISelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "J":
    //       return <JSelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "K":
    //       return <KSelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "L":
    //       return <LSelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "M":
    //       return <MSelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "N":
    //       return <NSelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "O":
    //       return <OSelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "P":
    //       return <PSelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "Q":
    //       return <QSelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "R":
    //       return <RSelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "S":
    //       return <SSelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "T":
    //       return <TSelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "U":
    //       return <USelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "V":
    //       return <VSelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "W":
    //       return <WSelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "X":
    //       return <XSelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "Y":
    //       return <YSelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     case "Z":
    //       return <ZSelected width={eachSeatWidth} height={eachSeatHeight} className="selected-seat" />;
    //     default:
    //       return <SeatSelectedIcon width={eachSeatWidth} height={eachSeatHeight} />;
    //   }
    // }
  };

  handleSeatClick = (seatType, seat, groupName, openToolTip) => {
    const { selectedSeats } = this.props;
    const { maxSeatCount } = this.state;
    let isSeatSofa = groupName === "" ? seat.name : groupName;
    let checkIfSeatAlreadySelected = selectedSeats.filter((item) => item.name === isSeatSofa).length ? true : false;

    if (selectedSeats.length < maxSeatCount || checkIfSeatAlreadySelected) {
      if (seatType === "sofa") {
        this.handleSofaSeatClick(seat, groupName);
      } else if (seatType === "normal") {
        this.handleTicketTypeTooltip(seat);
      } else {
        if (openToolTip) {
          this.handleTicketTypeTooltip(seat);
        } else {
          this.handleSeatTypeWarning(seat);
        }
      }
    } else {
      showAlert({
        html: `<p>You can only select upto <b>${maxSeatCount} Seat(s)</b> for this transaction</p>`,
        iconHtml: `<img src=${AlertIcon}>`,
        showCancelButton: false,
        cancelButtonText: "CANCEL",
        showConfirmButton: true,
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        iconClasses: "borderless",
        onConfirm: () => { },
        onCancel: () => { },
      });
    }
  };

  handleSofaSeatClick = (seat, sofaSeatGroupName) => {
    let singleSeatSelectionAllowed = this.props.allowSingleSeatSelection.find((item) => item.area === seat.area)?.isAllowed;
    this.closeTicketTypeTooltip(seat);
    if (singleSeatSelectionAllowed) {
      this.props.clearEmptyTooltipData();
      this.setState({ selectedSofaSeatGroupName: sofaSeatGroupName }, () => {
        let selectedSeatsTemp = this.props.selectedSeats;
        let selectedSofaSeat = selectedSeatsTemp.filter((selectedSeat) => selectedSeat.name === sofaSeatGroupName)[0];
        if (selectedSofaSeat) {
          this.handleSeatSelection(seat, selectedSofaSeat.ticketType);
        } else {
          this.handleTicketTypeTooltip(seat, sofaSeatGroupName);
        }
      });
    } else {
      let selectedSeatsTemp = this.props.selectedSeats;
      let selectedSofaSeat = selectedSeatsTemp.filter((selectedSeat) => selectedSeat.name === seat.name)[0];
      if (selectedSofaSeat) {
        this.handleSeatSelection(seat, selectedSofaSeat.ticketType);
      } else {
        this.handleTicketTypeTooltip(seat);
      }
    }
  };

  closeTicketTypeTooltip = (seat) => {
    let seatsDataTemp = this.state.seatsData;
    let singleSeatAllowed = this.state.allowSingleSeatSelection.find((item) => item.area === seat.area)?.isAllowed;
    seatsDataTemp.map((seatData) => {
      seatData.rowdefs.map((row) => {
        row.seats.map((seatData) => {
          if (seatData.style.toLowerCase() === "sofa") {
            if (singleSeatAllowed) {
              seatData.group.map((sofaSeat) => {
                sofaSeat.openToolTip = false;
                return sofaSeat;
              });
            } else {
              seatData.openToolTip = false;
            }
          } else {
            seatData.openToolTip = false;
          }
          return seatData;
        });
        return row;
      });
      return seatData;
    });
    this.setState({ seatsData: JSON.parse(JSON.stringify(seatsDataTemp)) });
  };

  handleSeatSelection = (seat, selectedSeatType) => {
    let selectedSeatsTemp = this.props.selectedSeats;
    let sofaSeatGroupName = this.state.selectedSofaSeatGroupName;
    let seatGrp;
    let singleSeatAllowed = this.props.allowSingleSeatSelection.find((item) => item.area === seat.area)?.isAllowed;
    let checkIfSeatIsSelected = selectedSeatsTemp.filter((selectedSeat) => selectedSeat.name === seat.name)[0];
    if (seat.style.toLowerCase() !== "sofa") {
      if (checkIfSeatIsSelected) {
        selectedSeatsTemp = selectedSeatsTemp.filter((selectedSeat) => selectedSeat.name !== seat.name);
        this.setState({ seatUnselected: true });
      } else {
        selectedSeatsTemp.push({ ...seat, ticketType: selectedSeatType });
        this.setState({ seatUnselected: false });
      }
    } else {
      if (singleSeatAllowed) {
        let sofaSeat = seat.group.filter((seatGroup) => seatGroup.name === sofaSeatGroupName)[0];
        sofaSeat.area = seat.area;
        sofaSeat.areadesc = seat.areadesc;
        let sofaSeatSelected = selectedSeatsTemp.filter((selectedSeat) => selectedSeat.name === sofaSeat.name)[0];
        if (sofaSeatSelected) {
          selectedSeatsTemp = selectedSeatsTemp.filter((selectedSeat) => selectedSeat.name !== sofaSeat.name);
          this.setState({ seatUnselected: true });
        } else {
          selectedSeatsTemp.push({ ...sofaSeat, ticketType: selectedSeatType });
          this.setState({ seatUnselected: false });
        }
      } else {
        if (checkIfSeatIsSelected) {
          selectedSeatsTemp = selectedSeatsTemp.filter((selectedSeat) => selectedSeat.name !== seat.name);
          this.setState({ seatUnselected: true });
        } else {
          selectedSeatsTemp.push({ ...seat, ticketType: selectedSeatType });
          this.setState({ seatUnselected: false });
        }
      }
    }

    this.closeTicketTypeTooltip(seat);
    if (selectedSeatsTemp.length === 1 && !this.state.changeTicketTypeTooltipShown) {
      this.handleChangeTicketTypeTooltip(true);
    }

    this.setState(
      {
        selectedTicketType: selectedSeatType,
        sofaSeatGroupName: null,
      },
      () => {
        this.props.setSelectedSeats(selectedSeatsTemp, () => {
          this.props.getTicketsSummary(seatGrp)
        });
      }
    );
  };

  handleTicketTypeTooltip = (seat, sofaSeatGroupName) => {
    if (!seat.openToolTip) {
      this.closeTicketTypeTooltip(seat);
    }
    let seatsDataTemp = this.state.seatsData;
    let selectedSeatsTemp = this.props.selectedSeats;
    let singleSeatAllowed = this.state.allowSingleSeatSelection.find((item) => item.area === seat.area)?.isAllowed;
    this.props.clearEmptyTooltipData();
    this.setState({ selectedSofaSeatGroupName: sofaSeatGroupName }, () => {
      // close all open tooltips and open the tooltip for the selected seat
      let checkIfSeatIsSelected;
      if (seat.style.toLowerCase() !== "sofa") {
        checkIfSeatIsSelected = selectedSeatsTemp.filter((selectedSeat) => selectedSeat.name === seat.name)[0];
      }
      if (seat.style.toLowerCase() === "sofa" && !singleSeatAllowed) {
        checkIfSeatIsSelected = selectedSeatsTemp.filter((selectedSeat) => selectedSeat.name === seat.name)[0];
      }
      // console.log(checkIfSeatIsSelected, "checkifSeattestging");
      if (checkIfSeatIsSelected) {
        // console.log("t");
        this.setState({ selectedTicketType: null });
        this.handleSeatSelection(seat, checkIfSeatIsSelected.ticketType);
        return;
      }
      if (this.state.applyToAllSeats && this.props.selectedSeats.length > 0 && this.state.selectedTicketType && this.state.selectedTicketType.area === seat.area && !this.state.seatUnselected) {
        this.handleSeatSelection(seat, this.state.selectedTicketType);
        return;
      }
      seatsDataTemp.map((seatData) => {
        seatData.rowdefs.map((row) => {
          row.seats.map((seatData) => {
            if (seatData.style.toLowerCase() === "sofa") {
              if (singleSeatAllowed) {
                seatData.group.map((sofaSeat) => {
                  if (sofaSeat.name === sofaSeatGroupName) {
                    sofaSeat.openToolTip = !sofaSeat.openToolTip;
                  } else {
                    sofaSeat.openToolTip = false;
                  }
                  return sofaSeat;
                });
              } else {
                if (seatData.name !== seat.name) {
                  seatData.openToolTip = false;
                } else {
                  seatData.openToolTip = !seatData.openToolTip;
                }
              }
            } else {
              if (seatData.name !== seat.name) {
                seatData.openToolTip = false;
              } else {
                seatData.openToolTip = !seatData.openToolTip;
              }
            }
            return seatData;
          });
          return row;
        });
        return seatData;
      });
      // console.log(seatsDataTemp, "seatsDataTemp");
      this.setState({ seatsData: JSON.parse(JSON.stringify(seatsDataTemp)) });
    });
  };

  handleChangeTicketTypeTooltip = (status) => {
    this.setState({ showChangeTicketTypeTooltip: status }, () => {
      if (status) {
        setTimeout(() => {
          this.handleChangeTicketTypeTooltip(false);
        }, 5000);
      }
    });
  };

  handleApplyToAllSeats = () => {
    this.setState({ applyToAllSeats: !this.state.applyToAllSeats });
  };

  handleSeatTypeWarning = (seat, sofaSeatGroupName) => {
    this.props.clearEmptyTooltipData();
    if (!seat.openToolTip) {
      this.closeTicketTypeTooltip(seat);
    }
    let selectedSeatsTemp = this.props.selectedSeats;
    let checkIfSeatIsSelected;
    if (seat.style.toLowerCase() !== "sofa") {
      checkIfSeatIsSelected = selectedSeatsTemp.filter((selectedSeat) => selectedSeat.name === seat.name)[0];
    }
    console.log("checkIfSeatIsSelected - > " + checkIfSeatIsSelected);
    if (checkIfSeatIsSelected) {
      console.log("inside checkIfSeatIsSelected");
      this.setState({ selectedTicketType: null });
      this.handleSeatSelection(seat, checkIfSeatIsSelected.ticketType);
      return;
    }
    let seatTypeAlertContent = this.state.seatTypeAlertContent;
    let alertContent = seatTypeAlertContent.filter((seatType) => seatType.name.toLowerCase().includes(seat.style.toLowerCase()));
    console.log("alertContent");
    console.log(alertContent);
    let alertTitle = alertContent.length ? alertContent[0].name : null;
    alertContent = alertContent.length ? alertContent[0].value : null;
    let alertIcon = seat.style.toLowerCase() === "wheelchair" ? <WheelchairOutlinedIcon /> : seat.style.toLowerCase() === "companion" && <CompanionOutlinedIcon />;
    let alertData = {
      icon: alertIcon,
      title: alertTitle,
      content: alertContent,
    };

    this.setState({ selectedAlertContent: alertContent, showSeatTypeAlert: true, alertData: alertData, seat: seat, selectedSofaSeatGroupName: sofaSeatGroupName });
  };

  getSiteSettingsData = async () => {
    await API.callEndpoint("GET", "Bearer", `/cms/v2/settings`)
      .then((response) => {
        let seatLayoutAlerts = response.data.filter((item) => item.type === "seatLayoutAlerts");
        let siteSettings;
        let maxSeatCountTemp;
        if (this.props.getMaxSeatsData) {
          siteSettings = response.data.filter((item) => item.type === "siteSettings");
          maxSeatCountTemp = siteSettings[0]?.configs.maxTicketsPerTransaction ? siteSettings[0]?.configs.maxTicketsPerTransaction : 10;
        } else {
          maxSeatCountTemp = process.env.REACT_APP_MAX_SEATS_LENGTH;
        }
        this.setState({
          seatTypeAlertContent: seatLayoutAlerts[0]?.configs,
          maxSeatCount: maxSeatCountTemp,
        });
      })
      .catch((ex) => {
        showAlert({
          html: `<p>Sorry<span>${ex.error}</span></p>`,
          iconHtml: `<img src=${infoIcon}>`,
          showCancelButton: false,
          cancelButtonText: "CANCEL",
          showConfirmButton: true,
          confirmButtonText: "OK",
          allowOutsideClick: false,
          allowEscapeKey: false,
          iconClasses: "borderless",
          popupClasses: "common-error-alert",
          onConfirm: () => {
            // window.location.href = "/";
          },
          onCancel: () => { },
        });
      });
  };

  getImageUrl = (seatImageName) => {
    console.log(seatImageName, "seatImageName");
    try {
      // Create a context for the images folder
      const context = require.context('../../assets/images/seat-icons', true, /\.(png|jpe?g|svg)$/);

      // Use the dynamic relative path to import the image
      return context(`./${seatImageName}`);
    } catch (error) {
      console.error("Image not found:", error);
      return null; // Return null or a fallback image if not found
    }
  }

  render() {
    const { emptyTooltipData, showCompanionSeats, showWheelChairSeats, showPremiumSeats, seatTypes } = this.props;
    const { zoomSeats, eachSeatWidth, eachSeatHeight, ticketTypeComponent, seatsData, allowSingleSeatSelection, applyToAllSeats, selectedSofaSeatGroupName, ticketTypes } = this.state;
    return (
      <>
        <section className="seat-types">
          <ul>
            {
              seatTypes.map((seatType, index) => {
                return (
                  <li key={index}>
                    <img src={this.getImageUrl(seatType.icon + "/seat-empty.svg")} alt={seatType.icon} />
                    <span>{seatType.icon}</span>
                  </li>
                )
              })
            }
            <li>
              <SeatOutlinedIcon />
              <span>Available</span>
            </li>
            <li>
              <SeatFilledIcon className="booked-seat" />
              <span>Sold</span>
            </li>
            <li>
              <SeatFilledIcon className="selected-seat" />
              <span>Your Seat(s)</span>
            </li>
            {showCompanionSeats ? (
              <li>
                <CompanionOutlinedIcon />
                <span> Companion</span>
              </li>
            ) : null}
            {showWheelChairSeats ? (
              <li>
                <WheelchairOutlinedIcon />
                <span> Wheelchair</span>
              </li>
            ) : null}
          </ul>
          <div className="zoomIconDiv" onClick={() => this.handleZoomSeats()}>
            {zoomSeats ? <FaMagnifyingGlassMinus className="zoomIcon" /> : <FaMagnifyingGlassPlus className="zoomIcon" />}
          </div>
        </section>
        <div className={`seats-container ${zoomSeats ? "zoomedIn" : ""}`} id="seats-container">
          {seatsData.length > 0 ? (
            <div className="seat-rows-container">
              <div className="seat-row" key={"screen-1"}>
                <div
                  id="screenImageBlankRowTitleLeft"
                  className="seat seat-row-title"
                  style={{
                    width: eachSeatWidth * (zoomSeats ? 2 : 1),
                    height: eachSeatHeight * (zoomSeats ? 2 : 1),
                  }}
                ></div>
                <div className="d-flex justify-content-center align-items-center">
                  <img src={ScreenView} alt="Screen View" className="screenImage" style={{ width: seatsData[0].cols * eachSeatWidth * (zoomSeats ? 2 : 1) }} />
                </div>
                <div
                  className="seat seat-row-title"
                  style={{
                    width: eachSeatWidth * (zoomSeats ? 2 : 1),
                    height: eachSeatHeight * (zoomSeats ? 2 : 1),
                  }}
                ></div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {seatsData.length > 0 ? (
            <>
              {seatsData.map((seatRow, index) => {
                return (
                  <div className="seat-rows-container" key={index}>
                    {seatRow.rowdefs.map((row, rowIndex) => {
                      let dataTooltipId = 0;
                      return (
                        <div className="seat-row" key={rowIndex}>
                          <div
                            className="seat seat-row-title"
                            style={{
                              width: eachSeatWidth * (zoomSeats ? 2 : 1),
                              height: eachSeatHeight * (zoomSeats ? 2 : 1),
                            }}
                          >
                            {row.name}
                          </div>
                          <div className="d-flex justify-content-center align-items-center">
                            {row.seats.map((seat, seatIndex) => {
                              const { eachSeatHeightCalculated, eachSeatWidthCalculated } = this.calculateEachSeatWidth(seat.style.toLowerCase());
                              dataTooltipId = seat.style.toLowerCase() === "sofa" ? dataTooltipId + 2 : dataTooltipId + 1;
                              return (
                                // <React.Fragment key={seatIndex}>
                                //   {seat.style.toLowerCase() === "sofa" ? (
                                //     <>
                                //       {allowSingleSeatSelection ? (
                                //         <div
                                //           className={`seat is-twin ${seat.status.toLowerCase() === "sold" || seat.status.toLowerCase() === "broken" ? "is-booked" : ""}`}
                                //           style={{
                                //             width: eachSeatWidthCalculated * 2 * (zoomSeats ? 2 : 1),
                                //             height: eachSeatHeightCalculated * (zoomSeats ? 2 : 1),
                                //           }}
                                //           data-tooltip-variant="dark"
                                //         >
                                //           {seat.group.map((group, groupIndex) => {
                                //             const SofaSeatSelected = this.checkIfSeatIsSelected(group.name);
                                //             return (
                                //               <>
                                //                 <button
                                //                   data-tooltip-id={`${row.name + (dataTooltipId - (groupIndex == 0 ? 1 : 0))}`}
                                //                   key={groupIndex}
                                //                   id={group.name}
                                //                   className={`sofa-seat-btn ${groupIndex == 0 ? "sofa-seat-left-btn" : "sofa-seat-right-btn"} ${SofaSeatSelected ? "selected-seat" : ""} ${seat.status.toLowerCase() === "sold" || seat.status.toLowerCase() === "broken" ? "is-booked" : ""
                                //                     }`}
                                //                   style={{
                                //                     width: "100%",
                                //                     height: "100%",
                                //                     pointerEvents:
                                //                       group.status.toLowerCase() === "sold" || group.status.toLowerCase() === "broken" || group.style.toLowerCase() === "passage" ? "none" : null,
                                //                   }}
                                //                   onClick={() => {
                                //                     if (group.hasOwnProperty("openToolTip") && group.openToolTip) {
                                //                       this.closeTicketTypeTooltip();
                                //                       return;
                                //                     }
                                //                     this.handleSeatClick("sofa", seat, group.name, group.openToolTip);
                                //                   }}
                                //                 >
                                //                   {/* {this.handleSofaSeatImage(seat, SofaSeatSelected, group.name, group.status)} */}
                                //                   <img src={this.handleSofaSeatImage(seat, SofaSeatSelected, group.name, group.status, groupIndex)} alt="" />
                                //                 </button>

                                //                 {ticketTypeComponent === "drawer" ? (
                                //                   <TicketTypeDrawer
                                //                     isOpen={group.openToolTip}
                                //                     onClose={this.closeTicketTypeTooltip}
                                //                     seat={seat}
                                //                     ticketTypes={ticketTypes}
                                //                     handleSeatSelection={this.handleSeatSelection}
                                //                     applyToAllSeats={applyToAllSeats}
                                //                     handleApplyToAllSeats={this.handleApplyToAllSeats}
                                //                     selectedSofaSeatGroupName={selectedSofaSeatGroupName}
                                //                     allowSingleSeatSelection={allowSingleSeatSelection}
                                //                   />
                                //                 ) : (
                                //                   group.openToolTip && (
                                //                     <TicketTypeTooltip
                                //                       closeTicketTypeTooltip={this.closeTicketTypeTooltip}
                                //                       show={group.openToolTip}
                                //                       seat={seat}
                                //                       ticketTypes={ticketTypes}
                                //                       handleSeatSelection={this.handleSeatSelection}
                                //                       applyToAllSeats={applyToAllSeats}
                                //                       handleApplyToAllSeats={this.handleApplyToAllSeats}
                                //                       selectedSofaSeatGroupName={selectedSofaSeatGroupName}
                                //                     />
                                //                   )
                                //                 )}
                                //               </>
                                //             );
                                //           })}
                                //         </div>
                                //       ) : (
                                //         <button
                                //           className={`sofa-seat-btn ${seat.status.toLowerCase() === "sold" || seat.status.toLowerCase() === "broken" ? "is-booked" : ""}`}
                                //           data-tooltip-id={`${row.name + dataTooltipId}`}
                                //           id={seat.name}
                                //           data-tooltip-variant="dark"
                                //           style={
                                //             seat.status.toLowerCase() === "sold" || seat.status.toLowerCase() === "broken" || seat.style.toLowerCase() === "passage" ? { pointerEvents: "none" } : null
                                //           }
                                //           onClick={() => {
                                //             if (seat.style.toLowerCase() === "passage") return;
                                //             if (seat.status.toLowerCase() === "sold" || seat.status.toLowerCase() === "broken") return;
                                //             this.handleSeatClick("sofa", seat, "", seat.openToolTip);
                                //           }}
                                //         >
                                //           {(seat.status.toLowerCase() === "sold" || seat.status.toLowerCase() === "broken") && (
                                //             <img src={TwinSeatBooked} alt="Sofa Seat" width={eachSeatWidthCalculated * 2} height={eachSeatHeightCalculated} />
                                //           )}
                                //           {this.checkIfSeatIsSelected(seat.name) === "selected-seat" && seat.status.toLowerCase() === "empty" && (
                                //             <img src={TwinSeatSelected} alt="Sofa Seat selected" width={eachSeatWidthCalculated * 2} height={eachSeatHeightCalculated} />
                                //           )}
                                //           {seat.status.toLowerCase() === "empty" && !this.checkIfSeatIsSelected(seat.name) && (
                                //             <img src={TwinSeat} alt="Sofa Seat" width={eachSeatWidthCalculated * 2} height={eachSeatHeightCalculated} />
                                //           )}
                                //         </button>
                                //       )}
                                //     </>
                                //   ) : (
                                //     <button
                                //       className={`seat is-single ${seat.style.toLowerCase() === "passage" ? "is-passage" : ""} ${seat.status.toLowerCase() === "sold" || seat.status.toLowerCase() === "broken" ? "is-booked" : ""
                                //         }`}
                                //       data-tooltip-id={`${row.name + dataTooltipId}`}
                                //       id={seat.name}
                                //       data-tooltip-variant="dark"
                                //       style={{
                                //         width: eachSeatWidthCalculated * (zoomSeats ? 2 : 1),
                                //         height: eachSeatHeightCalculated * (zoomSeats ? 2 : 1),
                                //         pointerEvents: seat.status.toLowerCase() === "sold" || seat.status.toLowerCase() === "broken" || seat.style.toLowerCase() === "passage" ? "none" : "null",
                                //       }}
                                //       onClick={() => {
                                //         if (seat.style.toLowerCase() === "passage") return;
                                //         if (seat.status.toLowerCase() === "sold" || seat.status.toLowerCase() === "broken") return;
                                //         if (seat.style.toLowerCase() === "normal") {
                                //           this.handleSeatClick("normal", seat, "", seat.openToolTip);
                                //         } else {
                                //           this.handleSeatClick("other", seat, "", seat.openToolTip);
                                //         }
                                //       }}
                                //     >
                                //       {/* {this.renderSeatImages(seat)} */}
                                //       {seat.style.toLowerCase() !== "passage" &&
                                //         <img src={this.renderSeatImages(seat)}></img>
                                //       }
                                //     </button>
                                //   )}
                                //   {ticketTypeComponent === "drawer" ? (
                                //     <TicketTypeDrawer
                                //       isOpen={seat.openToolTip}
                                //       onClose={this.closeTicketTypeTooltip}
                                //       seat={seat}
                                //       ticketTypes={ticketTypes}
                                //       handleSeatSelection={this.handleSeatSelection}
                                //       applyToAllSeats={applyToAllSeats}
                                //       handleApplyToAllSeats={this.handleApplyToAllSeats}
                                //       selectedSofaSeatGroupName={selectedSofaSeatGroupName}
                                //       allowSingleSeatSelection={allowSingleSeatSelection}
                                //     />
                                //   ) : (
                                //     seat.openToolTip && (
                                //       <TicketTypeTooltip
                                //         closeTicketTypeTooltip={this.closeTicketTypeTooltip}
                                //         show={true}
                                //         seat={seat}
                                //         ticketTypes={ticketTypes}
                                //         handleSeatSelection={this.handleSeatSelection}
                                //         applyToAllSeats={applyToAllSeats}
                                //         handleApplyToAllSeats={this.handleApplyToAllSeats}
                                //         selectedSofaSeatGroupName={selectedSofaSeatGroupName}
                                //         allowSingleSeatSelection={allowSingleSeatSelection}
                                //       />
                                //     )
                                //   )}
                                // </React.Fragment>
                                <React.Fragment key={seatIndex}>
                                  {seat.style.toLowerCase() === "sofa" ? (
                                    <>
                                      {
                                        allowSingleSeatSelection.map((allowSingleSeat) => {
                                          if (allowSingleSeat.area === seat.area) {
                                            if (allowSingleSeat.isAllowed) {
                                              return (
                                                <div
                                                  className={`seat is-twin ${seat.status.toLowerCase() === "sold" || seat.status.toLowerCase() === "broken" ? "is-booked" : ""}`}
                                                  style={{
                                                    width: eachSeatWidthCalculated * 2 * (zoomSeats ? 2 : 1),
                                                    height: eachSeatHeightCalculated * (zoomSeats ? 2 : 1),
                                                  }}
                                                  data-tooltip-variant="dark"
                                                >
                                                  {seat.group.map((group, groupIndex) => {
                                                    const SofaSeatSelected = this.checkIfSeatIsSelected(group.name);
                                                    return (
                                                      <>
                                                        <button
                                                          data-tooltip-id={`${row.name + (dataTooltipId - (groupIndex == 0 ? 1 : 0))}`}
                                                          key={groupIndex}
                                                          id={group.name}
                                                          className={`sofa-seat-btn ${groupIndex == 0 ? "sofa-seat-left-btn" : "sofa-seat-right-btn"} ${SofaSeatSelected ? "selected-seat" : ""} ${seat.status.toLowerCase() === "sold" || seat.status.toLowerCase() === "broken" ? "is-booked" : ""
                                                            }`}
                                                          style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            pointerEvents:
                                                              group.status.toLowerCase() === "sold" || group.status.toLowerCase() === "broken" || group.style.toLowerCase() === "passage" ? "none" : null,
                                                          }}
                                                          onClick={() => {
                                                            if (group.hasOwnProperty("openToolTip") && group.openToolTip) {
                                                              this.closeTicketTypeTooltip(seat);
                                                              return;
                                                            }
                                                            this.handleSeatClick("sofa", seat, group.name, group.openToolTip);
                                                          }}
                                                        >
                                                          {/* {this.handleSofaSeatImage(seat, SofaSeatSelected, group.name, group.status)} */}
                                                          <img src={this.handleSofaSeatImage(seat, SofaSeatSelected, group.name, group.status, groupIndex)} alt="" />
                                                        </button>

                                                        {ticketTypeComponent === "drawer" ? (
                                                          <TicketTypeDrawer
                                                            isOpen={group.openToolTip}
                                                            onClose={() => this.closeTicketTypeTooltip(seat)}
                                                            seat={seat}
                                                            ticketTypes={ticketTypes}
                                                            handleSeatSelection={this.handleSeatSelection}
                                                            applyToAllSeats={applyToAllSeats}
                                                            handleApplyToAllSeats={this.handleApplyToAllSeats}
                                                            selectedSofaSeatGroupName={selectedSofaSeatGroupName}
                                                            allowSingleSeatSelection={allowSingleSeatSelection}
                                                          />
                                                        ) : (
                                                          group.openToolTip && (
                                                            <TicketTypeTooltip
                                                              closeTicketTypeTooltip={() => this.closeTicketTypeTooltip(seat)}
                                                              show={group.openToolTip}
                                                              seat={seat}
                                                              ticketTypes={ticketTypes}
                                                              handleSeatSelection={this.handleSeatSelection}
                                                              applyToAllSeats={applyToAllSeats}
                                                              handleApplyToAllSeats={this.handleApplyToAllSeats}
                                                              selectedSofaSeatGroupName={selectedSofaSeatGroupName}
                                                            />
                                                          )
                                                        )}
                                                      </>
                                                    );
                                                  })}
                                                </div>
                                              )
                                            } else {
                                              const SofaSeatSelected = this.checkIfSeatIsSelected(seat.name);
                                              return (
                                                <button
                                                  className={`sofa-seat-btn ${seat.status.toLowerCase() === "sold" || seat.status.toLowerCase() === "broken" ? "is-booked" : ""}`}
                                                  data-tooltip-id={`${row.name + dataTooltipId}`}
                                                  id={seat.name}
                                                  data-tooltip-variant="dark"
                                                  style={{
                                                    width: eachSeatWidthCalculated * 2 * (zoomSeats ? 2 : 1),
                                                    height: eachSeatHeightCalculated * (zoomSeats ? 2 : 1),
                                                    pointerEvents:
                                                      seat.status.toLowerCase() === "sold" || seat.status.toLowerCase() === "broken" || seat.style.toLowerCase() === "passage" ? "none" : null,
                                                  }
                                                  }
                                                  onClick={() => {
                                                    if (seat.style.toLowerCase() === "passage") return;
                                                    if (seat.status.toLowerCase() === "sold" || seat.status.toLowerCase() === "broken") return;
                                                    this.handleSeatClick("sofa", seat, "", seat.openToolTip);
                                                  }}
                                                >
                                                  {/* {(seat.status.toLowerCase() === "sold" || seat.status.toLowerCase() === "broken") && (
                                                    <img src={TwinSeatBooked} alt="Sofa Seat" width={eachSeatWidthCalculated * 2} height={eachSeatHeightCalculated} />
                                                  )}
                                                  {this.checkIfSeatIsSelected(seat.name) === "selected-seat" && seat.status.toLowerCase() === "empty" && (
                                                    <img src={TwinSeatSelected} alt="Sofa Seat selected" width={eachSeatWidthCalculated * 2} height={eachSeatHeightCalculated} />
                                                  )}
                                                  {seat.status.toLowerCase() === "empty" && !this.checkIfSeatIsSelected(seat.name) && (
                                                    <img src={TwinSeat} alt="Sofa Seat" width={eachSeatWidthCalculated * 2} height={eachSeatHeightCalculated} />
                                                  )} */}
                                                  <img src={this.handleSofaSeatImage(seat, SofaSeatSelected, seat.name, seat.status)} alt={this.handleSofaSeatImage(seat, SofaSeatSelected, '', seat.status)} style={{
                                                    width: "100%",
                                                    height: "100%",
                                                  }} />
                                                </button>
                                              )
                                            }
                                          }
                                        })
                                      }



                                    </>
                                  )

                                    : (
                                      <button
                                        className={`seat is-single ${seat.style.toLowerCase() === "passage" ? "is-passage" : ""} ${seat.status.toLowerCase() === "sold" || seat.status.toLowerCase() === "broken" ? "is-booked" : ""
                                          }`}
                                        data-tooltip-id={`${row.name + dataTooltipId}`}
                                        id={seat.name}
                                        data-tooltip-variant="dark"
                                        style={{
                                          width: eachSeatWidthCalculated * (zoomSeats ? 2 : 1),
                                          height: eachSeatHeightCalculated * (zoomSeats ? 2 : 1),
                                          pointerEvents: seat.status.toLowerCase() === "sold" || seat.status.toLowerCase() === "broken" || seat.style.toLowerCase() === "passage" ? "none" : "null",
                                        }}
                                        onClick={() => {
                                          if (seat.style.toLowerCase() === "passage") return;
                                          if (seat.status.toLowerCase() === "sold" || seat.status.toLowerCase() === "broken") return;
                                          if (seat.style.toLowerCase() === "normal") {
                                            this.handleSeatClick("normal", seat, "", seat.openToolTip);
                                          } else {
                                            this.handleSeatClick("other", seat, "", seat.openToolTip);
                                          }
                                        }}
                                      >
                                        {/* {this.renderSeatImages(seat)} */}
                                        {seat.style.toLowerCase() !== "passage" &&
                                          <img src={this.renderSeatImages(seat)}></img>
                                        }
                                      </button>
                                    )}
                                  {ticketTypeComponent === "drawer" ? (
                                    <TicketTypeDrawer
                                      isOpen={seat.openToolTip}
                                      onClose={() => this.closeTicketTypeTooltip(seat)}
                                      seat={seat}
                                      ticketTypes={ticketTypes}
                                      handleSeatSelection={this.handleSeatSelection}
                                      applyToAllSeats={applyToAllSeats}
                                      handleApplyToAllSeats={this.handleApplyToAllSeats}
                                      selectedSofaSeatGroupName={selectedSofaSeatGroupName}
                                      allowSingleSeatSelection={allowSingleSeatSelection}
                                    />
                                  ) : (
                                    seat.openToolTip && (
                                      <TicketTypeTooltip
                                        closeTicketTypeTooltip={() => this.closeTicketTypeTooltip(seat)}
                                        show={true}
                                        seat={seat}
                                        ticketTypes={ticketTypes}
                                        handleSeatSelection={this.handleSeatSelection}
                                        applyToAllSeats={applyToAllSeats}
                                        handleApplyToAllSeats={this.handleApplyToAllSeats}
                                        selectedSofaSeatGroupName={selectedSofaSeatGroupName}
                                        allowSingleSeatSelection={allowSingleSeatSelection}
                                      />
                                    )
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </div>
                          <div
                            className="seat seat-row-title"
                            style={{
                              width: eachSeatWidth * (zoomSeats ? 2 : 1),
                              height: eachSeatHeight * (zoomSeats ? 2 : 1),
                            }}
                          >
                            {row.name}
                          </div>
                        </div>
                      );
                    })}
                    {emptyTooltipData?.status && emptyTooltipData?.id && <EmptySeatTooltip id={emptyTooltipData?.id} isOpen={emptyTooltipData?.status} />}
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <Skeleton height={47} width={50} count={15} containerClassName="skeleton-rows-container" />
              <Skeleton height={47} width={50} count={15} containerClassName="skeleton-rows-container" />
              <Skeleton height={47} width={50} count={15} containerClassName="skeleton-rows-container" />
              <Skeleton height={47} width={50} count={15} containerClassName="skeleton-rows-container" />
              <Skeleton height={47} width={50} count={15} containerClassName="skeleton-rows-container" />
            </>
          )}
        </div>
        <CustomModal
          show={this.state.showSeatTypeAlert}
          handleClose={() => {
            this.setState({ showSeatTypeAlert: false, alertData: {}, seat: {} });
          }}
          title={this.state.alertData.title}
          descriptionsList={[this.state.alertData.content]}
          icon={this.state.alertData.icon}
          iconWidth={"auto"}
          iconHeight={"100px"}
          showCancelBtn={true}
          cancelBtnText="cancel"
          showConfirmBtn={true}
          confirmBtnText="ACCEPT"
          onConfirm={() => {
            this.setState({ showSeatTypeAlert: false, alertData: {} }, () => {
              this.handleTicketTypeTooltip(this.state.seat, this.state.selectedSofaSeatGroupName);
            });
          }}
          onCancel={() => {
            this.setState({ showSeatTypeAlert: false, alertData: {}, seat: {} });
          }}
        />
      </>
    );
  }
}

export default Seats;
